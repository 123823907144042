export enum ToastMessage {
    Success = 'Request successful.',
    Failure = 'Unable to process the request.',
    Exception = 'Error occured.',
    UnAuthorized = 'Error 401 UnAuthroized',
    ValidationError = 'Request responded with validation error(s).',
    TokenExpired = 'Token Expired.',
    DataNotFound = 'Data Not Found.',
    DataAlreadyExists = 'Data Already Exists.',
    InvalidFileFormat = 'Invalid File Format.',
    NoFileUploaded = 'No File Uploaded',
    NoRecords = 'No records found.',
    DataExpired = 'Data Expired',
    PartialSuccess = 'Partially success, few items failed.',
    CompletedSuccessfully = 'Completed Successfully.',
    NotAcceptable = 'Current and New Password cannot be same.',
    AccessDenied = "Access Denied",
    Error = "Error",
    Deleted = "Deleted.",
    Updated = "Updated.",
    Added = "Added.",
    DeleteMessage = "Do you want to delete this record?",
    PasswordReset = "Check your email to reset your password.",
    ContactAdmin = "No Access to Any Modules,Please Contact Admin!",
    InternalServerError = "Internal Server Error",
    AccountLocked = "Account Locked",
    InvalidLogin = "Invalid Login",
    InvalidCredentials = "Invalid Credentials",
    PermanentDelete = "Do you want to delete this record permanently?",
}

export enum ToastCostomMessage {
    Added = "Data Added Succesfully!.",
    Updated = "Data Updated Successfully!.",
    Error = "Error occured, Please try Again!",
    FormRequired = "Please Fill All Required Fields.",
    PasswordUpdate = "Password Updated Successfully!",
    PasswordMatch = "Password Should Match!",
    NoteAdded = "The Notes Added Successfully",
    NoteRequired = "Please add Notes",
    Delete = "Deleted successfully.",
    AllFileRequire = "All fields are required",
    Submitted = "Submitted successfully.",
    RecordSaved = "The record has been saved successfully.",
    MaxFile = "Max 10 files are allowed to upload.",
    FileDelete = "The file has been deleted successfully.",
    EmailSent = "Email has been Sent",
    InvalidEmail = "You have entered an invalid email address!",
    RequestCreated = "Request created successfully",
    RequestProcessed = "Request processed successfully",
    NotificationSent = "Notification has been sent.",
    RoleDeletion = "Cannot Delete this Role!",
    EnterValidEmail = "Please Enter Valid Email",
}

export enum ToastDetails {
    Failed = 'Failed!',
    DeleteFailed = "Failed to Delete!",
    DeleteSuccess = "Deleted Successfully.",
    UpdateFailed = "Failed to Update!",
    UpdateSuccess = "Updated Successfully.",
    Duplicated = "Duplicate!",
    AddFailed = "Failed to Add!",
    AddSuccess = "Added Successfully.",
    SessionExpired = "Session Expired!"
}