import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject } from "rxjs";
import { ServiceGateway } from "../servicegatewayNew.service";
import { API_URLS, CONFIG, HTTP_VERBS } from "../../../config/config";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  constructor(private serviceGateWay: ServiceGateway) {}

  // Users API Call

  public getAll(data): Observable<any> {
    return this.serviceGateWay.post(
      API_URLS.Generic_api_url + API_URLS.Users_url + `/${HTTP_VERBS.Get}`,
      CONFIG.Content_Type,
      data
    );
  }

  public getById(data): Observable<any> {
    return this.serviceGateWay.post(
      API_URLS.Generic_api_url + API_URLS.Users_url + `/${HTTP_VERBS.GetById}`,
      CONFIG.Content_Type,
      data
    );
  }
  public getLoggedInUser(data): Observable<any> {
    return this.serviceGateWay.post(
      API_URLS.Generic_api_url +
        API_URLS.Users_url +
        `/${API_URLS.GetAuthUser}`,
      CONFIG.Content_Type,
      data
    );
  }

  public postData(data): Observable<any> {
    return this.serviceGateWay.post(
      API_URLS.Generic_api_url + API_URLS.Users_url,
      CONFIG.Content_Type,
      data
    );
  }

  public updateData(data): Observable<any> {
    return this.serviceGateWay.put(
      API_URLS.Generic_api_url + API_URLS.Users_url,
      CONFIG.Content_Type,
      data
    );
  }

  public deleteData(data): Observable<any> {
    return this.serviceGateWay.post(
      API_URLS.Generic_api_url + API_URLS.Users_url + `/${HTTP_VERBS.Delete}`,
      CONFIG.Content_Type,
      data
    );
  }

  public softDeleteData(data): Observable<any> {
    return this.serviceGateWay.post(
      API_URLS.Generic_api_url +
        API_URLS.Users_url +
        `/${API_URLS.UserSoftDelete}`,
      CONFIG.Content_Type,
      data
    );
  }
  public getAllTimeZones(): Observable<any> {
    return this.serviceGateWay.get(
      API_URLS.Generic_api_url +
        API_URLS.Users_url +
        "/" +
        API_URLS.GetTimeZone
    );
  }
}
