export enum ToastTitle {
    Success = 'Success',
    Error = 'Error',
    Warning = 'Warning',
    Info = 'Info',
    Delete = "Delete Confirmation",
}

export enum ToastSeverity{
    Success = 'success',
    Error = 'error',
    Warning = 'warning',
    Info = 'info'
}