import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";
import * as _ from "lodash";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable()
export class ServiceGateway {
  constructor(private http: HttpClient) {}
  // #docregion get the http request data
  public getwithouttoken(url: string): Observable<any> {
    return this.http
      .get(url, httpOptions)
      .pipe(map(this.extractData))
      .pipe(catchError(this.handleError<any>("Exception occured")));
  }

  public postwithouttoken(url: string, data: any): Observable<any> {
    return this.http.post<any>(url, data, httpOptions).pipe(
      tap((response: any) => console.log("post successfully")),
      catchError(this.handleError<any>("Exception occured"))
    );
  }

  public get2(url: string, token: string): Observable<any> {
    httpOptions.headers.append("Authorization", token);
    return this.http
      .get(url, httpOptions)
      .pipe(map(this.extractData))
      .pipe(catchError(this.handleError<any>("Exception occured")));
  }

  // #docregion get the http request data
  public get(url: string): Observable<any> {
   
    return this.http.get(url, this.getRequestOptions("")).pipe(
      tap((response) => response),
      catchError(this.handleError<any>("Exception occured"))
    );
  }

  // #docregion post the http data
  public post(url: string, contenttype: string, data: any): Observable<any> {
    return this.http
      .post<any>(url, data, this.getRequestOptions(contenttype))
      .pipe(
        tap((response: any) => console.log("Inserted successfully")),
        catchError(this.handleError<any>("Exception occured"))
      );
  }

  // public postForm(url: string, contenttype: string, data: FormData): Observable<any> {

  //     //console.log("Executing http.post, URL: " + url + " Time: " + new Date().toString(),'post data');
  //     return this.http.post<any>(url, data this.getRequestOptions(contenttype)).pipe(
  //         tap((response: any) => console.log('Inserted successfully')),
  //         catchError(this.handleError<any>('Exception occured'))
  //       );
  // }

  public post2(url: string, data: any): Observable<any> {
    //console.log("Executing http.post, URL: " + url + " Time: " + new Date().toString(),'post data');
    return this.http.post<any>(url, data, this.getTokenHeader()).pipe(
      tap((response: any) => console.log("Inserted successfully")),
      catchError(this.handleError<any>("Exception occured"))
    );
  }

  // post with error exception
  public post3(url: string, data: any): Observable<any> {
    return this.http.post<any>(url, data, this.getRequestOptions("")).pipe(
      tap((response: any) => console.log("Inserted successfully")),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  public put(url: string, contenttype: string, data: any): Observable<any> {
    //console.log("Executing http.post, URL: " + url + " Time: " + new Date().toString(),'post data');
    return this.http
      .put<any>(url, data, this.getRequestOptions(contenttype))
      .pipe(
        tap((response: any) => console.log("Update successfully")),
        catchError(this.handleError<any>("Exception occured"))
      );
  }

  // #docregion extract the data from response
  private extractData(res: Response) {
    // return response as json as desired format. change any[] to object type
    let data = res;
    return data || new Array<any>();
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private getRequestOptions(contentType: string): any {
    let authResponse: any = this.getAuthResponse();
    var _headers = {};
    //let headers = new HttpHeaders();
    if (
      contentType === "" ||
      contentType == null ||
      contentType === null ||
      contentType === "undefined"
    ) {
      //headers.append('Content-Type', 'application/json');
      _headers = _.extend(_headers, { "Content-Type": "application/json" });
    } else {
      //headers.append('Content-Type', contentType);
      _headers = _.extend(_headers, { "Content-Type": contentType });
    }

    if (
      authResponse == null ||
      authResponse === null ||
      typeof authResponse === "undefined"
    ) {
      console.error(
        "Cannot append Authorization header, authResponse is null or undefined"
      );
    } else {
      // headers.append('Authorization', authResponse.token_type + ' ' + authResponse.access_token);
      _headers = _.extend(_headers, {
        Authorization: "Bearer" + " " + authResponse,
      });
    }

    let httpOption = {
      headers: new HttpHeaders(_headers),
    };

    return httpOption;
  }

  private getTokenHeader(): any {
    let authResponse: any = this.getAuthResponse();
    var _headers = {};
    if (
      authResponse == null ||
      authResponse === null ||
      typeof authResponse === "undefined"
    ) {
      console.error(
        "Cannot append Authorization header, authResponse is null or undefined"
      );
    } else {
      _headers = _.extend(_headers, {
        Authorization: "Bearer" + " " + authResponse,
      });
    }
    let httpOption = {
      headers: new HttpHeaders(_headers),
    };
    return httpOption;
  }

  private getAuthResponse(): any {
    // let token = JSON.parse(sessionStorage.getItem('access_token'));
    let token = sessionStorage.getItem("access_token");
    return token;
  }
}
