<div class="ng-header">
  <div class="ng-header-left left">
    <div class="toggle-menu-button left" style="margin-top: 10px">
      <a class="icon" (click)="toggleMenu()"><i class="pi pi-bars"></i></a>
    </div>
    <div class="logo right">
      <img
        src="assets/images/docsnow-header-logo.svg"
        class="logo-large"
      />
      <img src="assets/images/docsnow-header-logo.svg" class="logo-small" />
    </div>
  </div>
  <div class="ng-header-right right">
    <div class="dropdown">
      <div class="dropdown-logo">
        <div class="dropbtn">
          <div class="user-info">
            <div class="p-avtar">
              <span class="p-avatar-text ng-star-inserted">{{
                logoName | uppercase
              }}</span>
            </div>
          </div>
        </div>
        <i class="fa fa-angle-down" aria-hidden="true"></i>
      </div>
      <div class="dropdown-content-header">
        <div class="user-details">
          <div class="margin-bottom-8" style="display: flex; flex-direction: column; gap: 8px;">
            <div class="header-h3" style="font-size: 20px;">{{ fullName }},</div>
            <div class="small-text" style="font-size: 14px;">{{ roleName }}</div>
          </div>
          <div class="margin-bottom-8 small-text">{{ emailId }}</div>
        </div>
        <div class="logout">
            <button class="primary-button" (click)="logout()">Log Out</button>
        </div>
      </div>
    </div>
  </div>
</div>
