import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/gaurds/auth.gaurd';
import { AppLayoutComponent } from 'src/app/shared/layout/app-layout.component';
import { HomeComponent } from './features/home/home.component';
import { ErrorComponent } from './shared/error/error.component';
import { MsalGuard } from '@azure/msal-angular';


const appRoutes: Routes = [
   {
        path: 'login',
        loadChildren: () => import('src/app/features/login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'forgot-password',
        loadChildren: () => import('src/app/features/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
    },
    {
        path: 'register',
        loadChildren: () => import('src/app/features/register-user/register-user.module').then(m => m.RegisterUserModule)
    },
    // {
    //     path: 'auth',
    //     component: SiteLayoutComponent,
    //     children: [
    //         {
    //             path: '',
    //             component: AuthComponent,
    //             canActivate: [MsalGuard]
    //         }
    //     ]
    // },
    {
        path: 'home',
        component: AppLayoutComponent,
        children: [
            {
                path: '',
                component: HomeComponent,
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: 'cms',
        component: AppLayoutComponent,
        children: 
        [
        {
            path: 'admin',
            loadChildren: () => import('src/app/features/admin/admin.module').then(m => m.AdminModule),
            canActivate: [AuthGuard]
        },
        {
            path: '',
            redirectTo: '/cms/admin',
            pathMatch: 'full'
        },
    ]
    },
    {
        path: 'error',
        component: ErrorComponent,
    },
    { path: '**', redirectTo: 'home'}
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }