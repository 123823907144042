// #docregion
import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import 'rxjs/add/observable/of';
import { ApiService } from './api.service';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { API_URLS, CONFIG, main_url } from 'src/app/config/config';
import { SessionService } from './session.service';
import { ServiceGateway } from './servicegatewayNew.service';
import { MsalService } from '@azure/msal-angular';
import { LoginType } from '../enum/login-type.enum';
import { Token } from '../models/token.model';

@Injectable()
export class AuthService {
    private loggedUserSubject: BehaviorSubject<any>;

    getAuthResponse(): any {
        return sessionStorage.getItem('access_token');
    }

    constructor(private router: Router, private servicegateway : ServiceGateway, private apiService: ApiService,public sessionService: SessionService,private msalService: MsalService,) {
    }

    public login(data: any): Observable<any> {
      return this.servicegateway.post3(API_URLS.Generic_api_url +
        API_URLS.Auth_url,
       data
     );
    }

    public forgotPassword(data: any): Observable<any> {
      return this.servicegateway.post3(API_URLS.Generic_api_url +
        API_URLS.ForgotPassowrd_url,
       data
     );
    }

    public isAuthenticated(): boolean {
      const token =  this.getAuthResponse();  
      if (!token) {
        return false;
      }
       return true;
    }

    
    public logout() {
      if(main_url.login_type == LoginType.USERNAME_PASSWORD) {
        sessionStorage.removeItem('access_token');
        sessionStorage.clear();
        this.router.navigateByUrl('/login');
      } else {
        sessionStorage.removeItem('access_token');
        sessionStorage.clear();
        this.msalService.logout();
      }
    }
  
    getTokenObjects(): any {
      let authResponse: any = this.getAuthResponse();    
      if (this.online(authResponse)) {
        let json_data = authResponse;
        let decodedToken = jwt_decode(json_data);
        let tokenObject = this.decodedTokenObjectCreation(decodedToken);
        return tokenObject;
      }
      
    }
  
    getExpirationTime(token: any) {
      var access_token: any = jwt_decode(token);
      if (access_token != undefined && access_token != null && access_token.exp) {
        return +access_token.exp;
      } else {
        return 0;
      }
    }
  
    online(session: any) {
      let json_session = session;
      let today = moment().utc().format("X");
      let currentTime = +today;

      if (json_session) {
        let expireTime = this.getExpirationTime(json_session);
        let sessionAndTokenValid: boolean =
          json_session && expireTime > currentTime;
        if (sessionAndTokenValid == null || sessionAndTokenValid == false)
          return false;
        else return sessionAndTokenValid;
      }
      return false
    }

    decodedTokenObjectCreation(decodedToken) {
      let tokenObject = new Token()
      if(main_url.login_type == LoginType.USERNAME_PASSWORD) {
        tokenObject.email = decodedToken?.email;
        tokenObject.name = decodedToken?.name;
        tokenObject.exp = decodedToken?.exp;
        tokenObject.tid = decodedToken?.tid;
      } else {
        tokenObject.email = decodedToken?.preferred_username;
        tokenObject.name = decodedToken?.name;
        tokenObject.exp = decodedToken?.exp;
        tokenObject.tid = decodedToken?.tid;
      }

      return tokenObject;
    }
}

