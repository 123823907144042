import { Component, OnInit } from "@angular/core";
import { SessionService } from "src/app/core/services/session.service";
import { User } from "src/app/core/models/user.model";
import { notification } from "src/app/core/models/notification.model";
import { ThemeService } from "src/app/core/services/theme.service";
import { MenuDataService } from "src/app/core/services/menu-data.service";
import { UsersService } from "src/app/core/services/admin-services/users-services";
import { MessageService } from "primeng/api";
import { AuthService } from "src/app/core/services/auth.service";
import { ToastDetails, ToastMessage } from "src/app/core/enum/toast-message.enum";
import { ToastSeverity } from "src/app/core/enum/toast-title.enum";

@Component({
  selector: "app-header",
  templateUrl: "header.component.html",
  styleUrls: ["header.component.css"],
})
export class HeaderComponent implements OnInit {
  user: User;

  displayNotifications: boolean;

  notifications: notification[];
  moduleCode: string = "9b751085-2511-4354-90d2-2d14d48d31c6";
  fullName: string = "";
  emailId: string = "";
  roleName: string = "";
  logoName: string = "";

  isLoader: boolean = false;
  constructor(
    private sessionService: SessionService,
    private themeService: ThemeService,
    private menuDataService: MenuDataService,
    private usersService: UsersService,
    private messageService: MessageService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    var obj = {
      moduleCode: this.moduleCode,
    };

    this.usersService.getLoggedInUser(obj).subscribe((response) => {
      if (response != null && response.statuscode != 4) {
        this.user = response;
        if(this.user) {
        this.logoName = this.user.firstName.charAt(0) + "" + this.user.lastName.charAt(0);
        this.fullName = this.user.firstName + " " + this.user.lastName;
        this.emailId = this.user.emailId;
        this.roleName = this.user.roleName;
        }
      } else if (response != null && response.statuscode == 4) {
        //UnAuthroized
        this.messageService.add({
          severity: ToastSeverity.Error,
          summary: ToastMessage.AccessDenied,
          detail: ToastDetails.Failed,
        });
      }
    });
  }

  logout() {
    this.authService.logout();
  }

  showNotificationSidebar() {
    this.displayNotifications = true;
  }

  toggleMenu() {
    this.menuDataService.toggleMenuBar.next(true);
  }

  selectTheme(theme: string) {
    this.sessionService.setItem("selected-theme", theme);
    this.themeService.selectTheme(theme);
  }
}
