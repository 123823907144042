import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { tap } from "rxjs/operators";
import { MessageService } from "primeng";
import { ToastSeverity } from "../enum/toast-title.enum";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.authService.isAuthenticated()) {
      const token = sessionStorage.getItem("access_token");
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    }
    return next.handle(request).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status == 401) {
              this.messageService.add({
                severity:ToastSeverity.Error,
                summary: "Session Expired",
                detail: "Your session has expired. Please log in again.",
                life: 5000, // Set the dialog to auto-close after 5 seconds
              });
              this.router.navigate(["login"]);
              return;
            }
          }
        }
      )
    );
  }
}
