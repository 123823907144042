import { Component, OnInit } from "@angular/core";
import { RouteStateService } from "src/app/core/services/route-state.service";
import { CustomMenuItem } from "src/app/core/models/menu-item.model";
import { MenuDataService } from "src/app/core/services/menu-data.service";
import { ApplicationStateService } from "src/app/core/services/application-state.service";
import { AuthService } from "src/app/core/services/auth.service";
import * as _ from "lodash";
import { RolePermissionsService } from "src/app/core/services/admin-services/rolepermissions-services";
import { MessageService } from "primeng/api";
import { ToastDetails, ToastMessage } from "src/app/core/enum/toast-message.enum";
import { ToastSeverity } from "src/app/core/enum/toast-title.enum";
import { CONFIG } from "src/app/config/config";


@Component({
  selector: "app-menu",
  templateUrl: "menu.component.html",
  styleUrls: ["menu.component.css"],
})
export class MenuComponent implements OnInit {
  items: CustomMenuItem[];
  menuItems: CustomMenuItem[] = [];
  selectedItem: string;
  visible: boolean = true;

  tokenObjectId: string = "";
  appCode: string = "1660211a-efed-46a6-ae68-77bf449eb6e2";
  permissionArr: any = [];
  loaderEnable: boolean = false;
  emailId: string = "";
  parentItem: string = "";
  appName: string = CONFIG.project_name;

  constructor(
    private routeStateService: RouteStateService,
    private menuDataService: MenuDataService,
    private authService: AuthService,
    private messageService: MessageService,
    private rolePermissionsService: RolePermissionsService,
    private applicationStateService: ApplicationStateService
  ) {}

  ngOnInit() {
    if (this.authService.isAuthenticated()) {
      this.loaderEnable = true;
      let decodedToken = this.authService.getTokenObjects();

      if (
        decodedToken != undefined &&
        decodedToken != null &&
        decodedToken?.email != undefined &&
        decodedToken?.email != null &&
        decodedToken?.email != ""
      ) {
        let homeObj: any = [];
        homeObj = [
          {
            childs: null,
            icon: "pi pi-home",
            isChildVisible: true,
            label: "Home",
            routerLink: "/home",
          },
        ];
        this.items = [];
        for (var home of homeObj) {
          this.items.push(home);
        }
        this.selectedItem = "Home";
      
          var projName = {
            emailId: decodedToken?.email,
            appName: this.appName,
          };
          this.rolePermissionsService.projectPermissionByName(projName).subscribe((response) => {
            if (response != null && response?.data.length > 0) {
              for (var menuObj of response?.data) {
                this.items.push(menuObj);
              }
              this.loaderEnable = false;
            } else {
              this.menuDataService.toggleMenuBar.next(true);
              this.messageService.add({
                severity: ToastSeverity.Error,
                summary: ToastMessage.AccessDenied,
                detail: ToastMessage.ContactAdmin,
                sticky: false,
              });
              this.loaderEnable = false;
            }
          });
      
      } else {
        this.messageService.add({
          severity: ToastSeverity.Error,
          summary: ToastMessage.Error,
          detail: ToastDetails.SessionExpired,
          sticky: false,
        });
        this.loaderEnable = false;
        this.authService.logout();
      }
    
      var that = this;
      this.menuDataService.toggleMenuBar.subscribe(function (data: any) {
        if (data && data != null) {
          that.visible = !that.visible;
        }
      });

      if (this.applicationStateService.getIsMobileResolution()) {
        this.visible = false;
      } else {
        this.visible = true;
      }

      var activeMenu = sessionStorage.getItem("active-menu");
      var parentItem = sessionStorage.getItem("active-parent");
      if (activeMenu) {
        this.selectedItem = activeMenu;
        this.parentItem = parentItem;
      } else {
        this.selectedItem = "Home";
        sessionStorage.setItem("active-menu", "Home");
      }
    }
  }

  ngOnDestroy() {
    this.menuDataService.toggleMenuBar.observers.forEach(function (element) {
      element.complete();
    });
  }

  sideBar() {
    this.visible = !this.visible;
  }

  // on menu click event
  onMenuClick(menu: CustomMenuItem) {
    // if child are available then open child
    let parentItem: any;
    if (menu.childs != undefined || menu.childs != null) {
      this.toggleSubMenu(menu);
      return;
    } else {
      parentItem = this.findParentItem(this.items, menu);
    }
    if (
      menu.routerLink == undefined ||
      menu.routerLink == null ||
      menu.routerLink == ""
    ) {
      this.routeStateService.add("Error 404", "/error", null, false);
      return;
    }
    this.selectedItem = menu.label;
    sessionStorage.setItem("active-menu", menu.label);
    sessionStorage.setItem("active-parent", parentItem.label);
    this.routeStateService.add(menu.label, menu.routerLink, null, true);
    // hide menu bar after menu click for mobile layout
    setTimeout(() => {
      if (this.applicationStateService.getIsMobileResolution()) {
        this.visible = false;
      }
    }, 100);
  }

  // toggle sub menu on click
  toggleSubMenu(menu: CustomMenuItem) {
    if(this.parentItem === menu.label){
      this.parentItem = null;
    }
    menu.isChildVisible = !menu.isChildVisible;
  }

  isMenuVisible(menuItem: CustomMenuItem) {
    if (menuItem) {
      if (this.parentItem === menuItem.label) {
        menuItem.isChildVisible = true;
      } else {
        menuItem.isChildVisible = false;
      }
      return menuItem.isChildVisible;
    }
  }

  //   find parent
  findParentItem(arr: any, searchItem: any) {
    for (let i = 0; i < arr.length; i++) {
      const currentItem = arr[i];
      if (currentItem.childs) {
        const parentItem = this.findParentItem(currentItem.childs, searchItem);
        if (parentItem) {
          return currentItem;
        }
      }
      if (currentItem.label === searchItem.label) {
        return currentItem;
      }
    }
    return null;
  }
}
