<div class="row error-box1">
  <img src="assets/images/welcome.svg" alt="welcome-svg">
  <div style="margin-top: 32px">
    <div class="header-h2 margin-bottom-16">Welcome '{{ userName }}'</div>
    <div class="secondary-text">Explore the CMS-ADMIN System.</div>
    <p *ngIf="!isPermissionExists" style="color: var(--color-error);">
      <i class="pi pi-exclamation-triangle"></i> Oops!! You have No Access to
      Any Modules! Please Contact Admin.
    </p>
  </div>
</div>

<!-- <div class="np-loader" *ngIf="loaderEnable">
  <p-progressSpinner strokeWidth="2"></p-progressSpinner>
</div> -->
