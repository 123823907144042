import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { ServiceGateway } from '../servicegatewayNew.service';
import { API_URLS, CONFIG, HTTP_VERBS } from '../../../config/config';


@Injectable({
    providedIn: 'root',
})
export class RolePermissionsService {

    constructor(private serviceGateWay :ServiceGateway ) {
    }

    public getAll(): Observable<any> {
        return this.serviceGateWay.get(API_URLS.Generic_api_url + API_URLS.RolePermissions_url);
    }

    public getById(id: string){
        return this.serviceGateWay.get(API_URLS.Generic_api_url + API_URLS.RolePermissions_url + '/'  + id);
    }

    public getRolesStructByid(id: string){
        return this.serviceGateWay.get(API_URLS.Generic_api_url + API_URLS.RolePermissions_url + `/${API_URLS.GetRolesStructByid}` +'/' + id);
    }
    
    public postData(data): Observable<any> {
        return this.serviceGateWay.post(API_URLS.Generic_api_url + API_URLS.RolePermissions_url, CONFIG.Content_Type, data);
    }

    public updateData(data): Observable<any> {
        return this.serviceGateWay.put(API_URLS.Generic_api_url + API_URLS.RolePermissions_url, CONFIG.Content_Type, data);
    }

    public deleteData(id): Observable<any> {
        return this.serviceGateWay.post(API_URLS.Generic_api_url + API_URLS.RolePermissions_url + '/' + id, CONFIG.Content_Type, null);
    }
    public modulePermission(data): Observable<any> {
        return this.serviceGateWay.post(API_URLS.Generic_api_url + API_URLS.RolePermissions_url + `/${API_URLS.GetPermByModCode}`, CONFIG.Content_Type, data);
    }
    public projectPermission(data): Observable<any> {
        return this.serviceGateWay.post(API_URLS.Generic_api_url + API_URLS.RolePermissions_url + `/${API_URLS.GetPermByProjCode}`, CONFIG.Content_Type, data);
    }

    public projectPermissionByName(data) : Observable<any> {
        return this.serviceGateWay.post(API_URLS.Generic_api_url + API_URLS.RolePermissions_url + `/${API_URLS.GetPermByProjName}`, CONFIG.Content_Type, data);
    }

    public tenantInfo(): Observable<any> {
        return this.serviceGateWay.post(API_URLS.Generic_api_url + API_URLS.RolePermissions_url + `/${API_URLS.GetTenantInfo}`, CONFIG.Content_Type,null);
    }

    public projInfo(data): Observable<any> {
        return this.serviceGateWay.post(API_URLS.Generic_api_url + API_URLS.RolePermissions_url + `/${API_URLS.GetProjectsByEmail}`, CONFIG.Content_Type, data);
    }  

    public getModuleCode(slug: string) {
        return this.serviceGateWay.get(API_URLS.Generic_api_url + API_URLS.RolePermissions_url + '/' + slug);
    }
}

