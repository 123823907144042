// angular default
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Prime NG
import { MessageService } from 'primeng/api';
// app related
import { AppComponent } from 'src/app/app.component';
import { AuthGuard } from 'src/app/core/gaurds/auth.gaurd';
import { AppRoutingModule } from 'src/app/app.routing.module';
import { AppLayoutComponent } from 'src/app/shared/layout/app-layout.component';
import { MenuComponent } from 'src/app/shared/layout/menu/menu.component';
import { HeaderComponent } from 'src/app/shared/layout/header/header.component';
import { FooterComponent } from 'src/app/shared/layout/footer/footer.component';
import { UserIdleModule } from 'angular-user-idle';
import { HttpClientModule, HttpClient ,HTTP_INTERCEPTORS} from '@angular/common/http';
import { AppCommonModule } from 'src/app/app.common.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthService } from './core/services/auth.service';
import { ApiService } from './core/services/api.service';
import { ServiceGateway } from './core/services/servicegatewayNew.service';
import { JwtModule } from "@auth0/angular-jwt";
import { MsalInterceptor} from "@azure/msal-angular";
import { HomeComponent } from './features/home/home.component';
import { MsalService } from '@azure/msal-angular';
import { MSAL_CONFIG } from '@azure/msal-angular/dist/msal.service';
import {MsalModule} from "@azure/msal-angular";
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

export function loggerCallback(logLevel, message, piiEnabled) {
  console.log("client logging" + message);
}

 export const protectedResourceMap:[string, string[]][]=[['https://graph.microsoft.com/v1.0/me', ['user.read']] ];

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function tokenGetter() {
  return sessionStorage.getItem("access_token");
}

@NgModule({
  declarations: [
    AppComponent,
    AppLayoutComponent,
    MenuComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent
  ],
  imports: [        
    BrowserModule,   
    BrowserAnimationsModule,   
    AppRoutingModule,
    NgxIntlTelInputModule,
    UserIdleModule.forRoot({ idle: 300, timeout: 1, ping: null }),
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ["example.com"],
        blacklistedRoutes: ["http://example.com/examplebadroute/"],
      },
    }),
    MsalModule.forRoot({
      clientID: 'e05cdd21-0b1a-4e4d-8c22-5c4d03ddb84d',
      authority: "https://login.microsoftonline.com/8a49dc45-81ea-45dc-ba0a-eb06c1264550/",
      validateAuthority: true,
      redirectUri : window.location.origin + "/home",
      cacheLocation : "sessionStorage",
      storeAuthStateInCookie: isIE, // set to true for IE 11
      postLogoutRedirectUri: window.location.origin,
      navigateToLoginRequestUrl: false,
      consentScopes: [ "user.read", "openid", "profile"],
      unprotectedResources: ["https://www.microsoft.com/en-us/"],
      protectedResourceMap: protectedResourceMap,
      logger: loggerCallback,
      piiLoggingEnabled: true
    }), 
    AppCommonModule,
   
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }   
    })
  ],
  exports: [TranslateModule],
  providers: [
    MessageService,
    AuthGuard,
    AuthService, 
    ServiceGateway,
    ApiService,
    MsalService,
    {provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }