import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/core/services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { AuthService } from './core/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Docsnow';
  showLoader: boolean;
  theme: string;

  constructor(
    private loaderService: LoaderService,
    private broadcastService: BroadcastService,
    private msalService: MsalService,
    private authService: AuthService,
    translate: TranslateService,
    private router: Router,
  ) {
  
  }

  ngOnInit() {
    let authResponse = sessionStorage.getItem('access_token');
    if (authResponse) {
      if (!this.authService.online(authResponse)) {
        sessionStorage.clear();
        this.router.navigateByUrl('/login');

        // this.msalService.logout();
      }
    }

    this.broadcastService.subscribe('msal:loginSuccess', (payload) => {
      console.log( JSON.stringify(payload));
      console.log( payload._token);
      sessionStorage.setItem('access_token', payload._token);
    });
    this.loaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
    });

  
  }

  ngOnDestroy() {   
    this.loaderService.status.observers.forEach(function (element) { element.complete(); });
  }


}
