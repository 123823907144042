import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomMenuItem } from '../models/menu-item.model';

@Injectable({
    providedIn: 'root',
})
/**
 * menu data service
 */
export class MenuDataService {

    public toggleMenuBar: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    public wizardStepBar: BehaviorSubject<any> = new BehaviorSubject<number>(0);

    // getMenuList(): CustomMenuItem[] {
    //     return [
    //         {
    //             label: 'Home', icon: 'fa-home', routerLink: '/main/dashboard', childs: null, isChildVisible: false
    //         },
    //         {
    //             label: 'Administration', icon: 'fa-cog', routerLink: '/main/admin', childs: [
    //                 {
    //                     label: 'Users', icon: 'fa-user', routerLink: '/main/admin/users', childs: null, isChildVisible: false
    //                 },
    //                 {
    //                     label: 'Roles', icon: 'fa-user-md', routerLink: '/main/admin/roles', childs: null, isChildVisible: false
    //                 },
    //                 {
    //                     label: 'Apps', icon: 'fa-user-md', routerLink: '/main/admin/apps', childs: null, isChildVisible: false
    //                 }
    //             ], isChildVisible: false
    //         }
    //     ];
    // }

    getMenuList(): CustomMenuItem[] {
        return [
            {  
                label: 'Administration', icon: 'fa-cogs', routerLink: 'dashboard/main/admin', childs: [
                     
                    {
                        label: 'Projects', icon: 'fa-th', routerLink: 'dashboard/main/admin/projects', childs: null, isChildVisible: false
                    },
                    {
                        label: 'Modules', icon: 'fa-list-alt', routerLink: 'dashboard/main/admin/modules', childs: null, isChildVisible: false
                    },
                    {
                        label: 'Roles', icon: 'fa-user-md', routerLink: 'dashboard/main/admin/roles', childs: null, isChildVisible: false
                    },
                    {
                        label: 'Users', icon: 'fa-user', routerLink: 'dashboard/main/admin/users', childs: null, isChildVisible: false
                    },
                       
                    // {
                    //     label: 'Parent Modules', icon: 'fa-sticky-note', routerLink: '/main/admin/parentmodules', childs: null, isChildVisible: false
                    // }, 
                     
                ], isChildVisible: true
            },
            
        ];
    }
}