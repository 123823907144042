import { Component, OnInit } from "@angular/core";
import { RolePermissionsService } from "src/app/core/services/admin-services/rolepermissions-services";
import { AuthService } from "src/app/core/services/auth.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["home.component.css"],
})
export class HomeComponent implements OnInit {
  userName: string = "";
  loaderEnable: boolean = false;
  isPermissionExists: boolean = true;

  constructor(private permissionsService: RolePermissionsService , private authService: AuthService,) {}

  ngOnInit() {
    // this.loaderEnable = true;
    const decodedToken = this.authService.getTokenObjects();
    this.userName = decodedToken?.name;
    var obj = {
      emailId: decodedToken?.email,
    };
    // this.permissionsService.projectPermission(obj).subscribe((data) => {
    //   if (data != null && data.length > 0) {
    //     this.isPermissionExists = true;
    //     this.loaderEnable = false;
    //   } else {
    //     this.isPermissionExists = false;
    //     this.loaderEnable = false;
    //   }
    // });
  }
}
