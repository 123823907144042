export var CONFIG = {
  Local_API_Url: "http://localhost:5002/api/",
  // Dev_API_Url: "https://ms-docsnow-cms-india-dev.azurewebsites.net/api/",
  Dev_API_Url: "https://bg-docsnow-cms-api-dev.baygrape.com/api/",
  Qa_API_Url: "https://bg-docsnow-cms-api-qa.baygrape.com/api/",
  Trial_API_Url: "https://trial-cms-api.docsnow.io/api/",
  Tenant_Code: "50526923-6957-4ab9-a404-4085211e2b20",
  Global_Admin_Code: "279cd518-ea00-4e81-a65a-06d731ca899e",
  Content_Type: "application/json",
  project_name: "DocsNow",
};

export var main_url: any = {
  api_url: CONFIG.Trial_API_Url,
  login_type: "USERNAME_PASSWORD", //  value will be 'USERNAME_PASSWORD' FOR JWT  login, Value will be 'AD_LOGIN' for Microsoft login
};

export var API_URLS: any = {
  Generic_api_url: main_url.api_url,
  Auth_url: "admin/login",
  ForgotPassowrd_url: "admin/forgot",

  Users_url: "UsersInfo",
  Roles_url: "RolesInfo",
  Permissions_url: "PermissionsInfo",
  RolePermissions_url: "RolePermissions",
  Apps_url: "Projects",
  Modules_url: "ModulesInfo",
  Tenants_url: "Tenants",
  ModuleLevels_url: "ModuleLevels",

  GetProjByTenantCode: "getProjByTenantCode",
  GetModulesTreeStruct: "getModulesTreeStruct",
  GetRolesStructByid: "getRolesStructByid",
  GetPermByModCode: "getPermByModCode",
  GetPermByProjCode: "getPermByProjCode",
  GetPermByProjName: "GetPermByProjName",
  GetTenantInfo: "getTenantInfo",
  GetProjectsByEmail: "getProjectsByEmail",
  GetRoleByTenantCode: "getRoleByTenantCode",
  GetRolesByEmail: "getRolesByEmail",
  GetAuthUser: "GetAuthUser",
  CheckProjectForDelete: "CheckProjectForDelete",
  CheckRolesForDelete: "CheckRolesForDelete",
  ProjectSoftDelete: "ProjectSoftDelete",
  UserSoftDelete: "UserSoftDelete",
  SoftDeleteRole: "SoftDeleteRole",
  SoftDelete: "SoftDelete",
  ModuleSoftDelete: "ModuleSoftDelete",
  GetTimeZone: "getTimeZone",
};

export const HTTP_VERBS: any = {
  Get: "get",
  GetById: "getById",
  Post: "post",
  Update: "put",
  Delete: "delete",
  Post2: "post2",
  Get2: "get2",
};
